import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Image src="/news/1stApril_logoLena.jpeg" className="w-full mx-auto my-12" mdxType="Image" />
    <br />
    <p>{`Dette er et stort steg for oss og et viktig steg for alle syklister der ute. For
vår del forsterker dette troen på at Trailguide er kommet for å bli og at vi nå kan
utfordre de større selskapene i enda større grad enn tidligere, forteller daglig
leder Tonje Sameien.`}</p>
    <br />
    <p>{`Satellittselskapet “LENA” er lokalisert i Ulanbatar, litt sør-øst i Mongolia.`}</p>
    <br />
    <p>{`Selskapets lokasjon var alfa-omega for oss. Siden vi har god kompetanse på kart,
brukte vi kunnskapene våre til å definere valgkriterier for hvilket selskap vi
skulle gå for i utvalgsprosessen. Hvis du åpner google-maps og zoomer helt ut,
vil du se at Ulanbatar ligger midt på kartet. Denne posisjonen gir maks uttelling
på dekningen i hele verden, fortsetter Sameien.`}</p>
    <br />
    <Image src="/news/1stApril_LENA.jpeg" className="w-full mx-auto my-12" mdxType="Image" />
    <br />
    <h4>{`Skal bekjempe “stravasyklister” med eget prikk-system`}</h4>
    <p>{`En av de hyppigste tilbakemeldingene vi får fra syklister, turgåere og spesielt
folk som driver med hesteridning, er slitasjen som forekommer på sti. De fleste
henviser da til såkalte “stravalinjer” som kort fortalt er snarveier som går
utenfor den allerede etablerte stien, i et mål om å sykle raskere.`}</p>
    <br />
    <p>{`Dette er en utfordring vi har jobbet lenge med å løse og det nye samarbeidet
åpner opp for alternativer som vi tror vil fungere veldig bra for å redusere
antall snarveier og unødvendig slitasje på sti, sier Sameien.`}</p>
    <br />
    <p>{`Hverken Trailguide eller LENA har noe imot folk som sykler fort, men det er når
hastigheten går på bekostning av stien og andre brukere, at det blir problematisk.
Konkurransesykling er en viktig del av manges sykling og har en stor motivasjonell
effekt. Derfor er det viktig å respektere denne delen av stisykling, men slå hardt
ned på folk som ikke viser hensyn og som sykler utenfor stien.`}</p>
    <br />
    <p>{`Hvordan vi skal løse dette problemet er fortsatt i tidlig fase, men vi ruller
ut en pilot over påske som er basert på prikksystemet for bilister, forteller Sameien.`}</p>
    <br />
    <p>{`Når vi slår oss sammen får vi mulighet til å utvikle GPS-teknologien vår
ytterligere og endelig kan vi få bukt med såkalte «stravalinjer». Ved hjelp av
daglige oppdaterte oversiktsbilder fra satellitter og flylaser får vi varsler hver
gang det etableres et nytt spor utenfor den opprinnelige stien. Dette varselet
kjøres da inn i en database som samler inn GPS-data fra alle påslåtte mobiltelefoner,
og slik kan vi avdekke hvem som ikke overholder «reglene», fortsetter hun.`}</p>
    <br />
    <p>{`«Stravalinjer» vil bli straffet etter samme prinsipp som brudd på trafikkreglene.
Sykler du utenfor stien vil du få en prikk registrert på brukeren din. Får du mer
enn fire prikker i løpet av en måned, blir du kastet ut av mobilnettet og mister
dekningen i en periode på seks måneder. Du får også en bot på 500kr, sier Sameien.`}</p>
    <Image src="/news/strava_shortcuts3.jpg" className="w-full mx-auto my-12" mdxType="Image" />
    <br />
    <p>{`Vi er også i gang med å utvikle en lignende løsning for å få kontroll på “stitroll”
som legger pinner og steiner over stien i sitt forsøk på å stoppe syklister.
Dersom syklister oppdager hendelser på stien, kan de registrere dette i Trailguide,
og vi vil da ha en egen avdeling sammen med LENA som bruker satellitt og
ansiktsgjenkjenningsteknologi for å finne synderne og anmelde dem til politiet,
sier Sameien.`}</p>
    <br />
    <p>{`Selv om det gjøres et omfattende arbeid for å redusere konkurransejaget på stiene,
vil også vi implementere leaderboards som du finner i eksempelvis Strava.
Derimot vil disse omtales som HKOM/HQOM - honest king/queen of the mountain.`}</p>
    <br />
    <p>{`Hvem som helst syns det er gøy å se navnet sitt på toppen av en liste, eller
kunne sammenligne seg med andre og seg selv. Dersom du er raskeste mann/kvinne
på en sti - på ærlig vis, vil ansiktet ditt være synlig i Trailguidekartet.
Dette er en morsom gimmick vi håper folk vil like, forteller Tonje Sameien.`}</p>
    <Image src="/news/strava_koms_on_trailguide3.jpg" className="w-full mx-auto my-12" mdxType="Image" />
    <br />
    <h4>{`Nullstiller alle tider etter hver sesong`}</h4>
    <p>{`Vi har fått mange tilbakemeldinger på at tider flere år tilbake er umulige og slå,
da stiene så helt annerledes ut da enn de gjør nå. Dette er en faktor som bidrar
til at «strava-linjer» lages, og vi håper at en nullstilling av alle tider etter
hver sesong gjør at folk ikke trenger å sykle utenfor stien for å være raskest.
Vi tror også dette vil bidra til mer realistiske tider. Vi innfører også egne
leaderboards på vinter og sommer i enkelte regioner, etter ønske fra brukerne
våre, avslutter Sameien.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      